import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { Loader } from 'semantic-ui-react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { TokenModel, TransactionModel } from '../../models';
import { Http } from '../../services/Http';
import on from '../../utils/Async';
import styled from 'styled-components';
import { Config } from '../../Config';

console.log(JSON.stringify(Config, null, 2));

interface AutoSubmittingFormProps {
	transaction: TransactionModel;
	checksum: string;
}
const AutoSubmittingForm: React.FC<AutoSubmittingFormProps> = ({
	transaction,
	checksum,
}) => {
	const formRef = useRef<HTMLFormElement>(null);

	useLayoutEffect(() => {
		formRef?.current?.submit();
	}, []);

	return (
		<form method="POST" action={Config.peach.checkoutUrl} ref={formRef} name="form" acceptCharset="utf-8">
			<input type="hidden" name="amount" value={transaction.amount + transaction.cost} />
			<input type="hidden" name="authentication.entityId" value={Config.peach.authEntityId} />
			<input type="hidden" name="currency" value="ZAR" />
			<input type="hidden" name="defaultPaymentMethod" value="CARD" />
			<input type="hidden" name="merchantTransactionId" value={transaction._id} />
			<input type="hidden" name="nonce" value={transaction._id} />
			<input type="hidden" name="paymentType" value="DB" />
			<input type="hidden" name="shopperResultUrl" value={Config.peach.successUrl} />
			<input type="hidden" name="signature" value={checksum} />
		</form>
	);
};

const Peach = ({ location, className }) => {
	const [transaction, setTransaction] = useState<TransactionModel>();
	const {tokenId, checksum} = queryString.parse(location.search);
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		if (!checksum) {
			setError(true);
			return;
		}

		(async function fetchTransaction() {
			const [tokenError, token] = await on(Http.getTransactionToken(tokenId));
			if (tokenError) {
				console.error(tokenError);
				setError(true);
				return;
			}

			setTransaction(token!.entity);
		})();
	}, [tokenId]);

	if (error) {
		return (
			<Layout className={className}>
				<SEO title="Pay" />
				<div className="message">
					<span>Something went wrong :(</span>
					<span>An unexpected error occured, please return to the app and try again.</span>
					<span>You may close this window.</span>
				</div>
			</Layout>
		);
	}

	if (!transaction) {
		return (
			<Layout className={className}>
				<SEO title="Pay" />
				<Loader
					active
				/>
			</Layout>
		);
	}

	console.log('peach', {checksum, tokenId, transaction,});

	return (
		<Layout className={className}>
			<SEO title="Pay" />
			<AutoSubmittingForm
				transaction={transaction}
				checksum={checksum as string}
			/>
		</Layout>
	);
};

export default styled(Peach)`
  display: flex;
  flex-direction: column;
  height: 100vh;

  > .body {
    flex: 1;

    .layout {
      height: 100%;
      display: flex;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .message {
        color: var(--color-white);
        display: flex;
        line-height: 1.5;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 200px;
        background-color: linear-gradient(to left, var(--color-accent), var(--color-primary));
        background-color: var(--color-dark-grey);
        border-radius: 3px;

        span:nth-child(1) {
          font-size: 1.5rem;
          font-weight: 700;
        }
        span:nth-child(2), span:nth-child(3) {
          opacity: 0.7;
        }

        a {
          margin-top: 30px;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
`;
